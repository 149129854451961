var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"projectData"},[_c('el-form',{staticClass:"ly-header__bg",attrs:{"model":_vm.queryInfo,"label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"所在地区："}},[_c('cascader-area',{attrs:{"country":_vm.queryInfo.country,"province":_vm.queryInfo.province,"city":_vm.queryInfo.city},on:{"searchCountry":_vm.searchCountry,"searchProvince":_vm.searchProvince,"searchCity":_vm.searchCity}})],1),_c('el-form-item',{attrs:{"label":"行政级别："}},[_c('dic-radio-button',{attrs:{"code":_vm.queryInfo.administrativeLevel,"type-code":"031","query-name":"administrativeLevel","name":"行政级别"},on:{"update:code":function($event){return _vm.$set(_vm.queryInfo, "administrativeLevel", $event)},"onChange":_vm.onChange}})],1),_c('el-form-item',{staticClass:"filterInfo",attrs:{"label":"筛选条件："}},_vm._l((_vm.tags),function(item,index){return _c('el-tag',{key:index+item.name,attrs:{"closable":""},on:{"close":function($event){return _vm.closeTag(index,item.tagName)}}},[_vm._v(" "+_vm._s(item.name)+"："+_vm._s(_vm.queryInfo[item.tagName]?_vm.queryInfo[item.tagName]:'不限')+" "),(item.hasOwnProperty('valLength')&&item.valLength>0)?_c('span',{staticClass:"circle"},[_vm._v("+"+_vm._s(item.valLength))]):_vm._e()])}),1)],1),_c('div',{staticClass:"ly-container__bg"},[_c('div',{staticClass:"ly_operation"},[_c('div',{staticClass:"left"},[_c('el-button',{attrs:{"type":"primary","icon":"jr-iconjr-icon-add"},on:{"click":_vm.addItem}},[_vm._v("添加")]),_c('el-button',{on:{"click":_vm.multiDelete}},[_vm._v(" 批量删除 "),_c('i',{staticClass:"jr-iconjr-icon-delect"})]),_c('popover',{on:{"popoverClick":_vm.popoverClick}}),_c('div',{staticClass:"upload_time"},[_c('div',{staticClass:"caretTime",staticStyle:{"color":"#606266"},on:{"click":_vm.uploadTime}},[_vm._v("按上传时间")]),_c('i',{staticClass:"el-icon-caret-top",class:[
              _vm.queryInfo.columnName == 'create_time' && _vm.queryInfo.order == 0
                ? 'isActiveTime1'
                : 'iconColor' ],on:{"click":_vm.caretTop}}),_c('i',{staticClass:"el-icon-caret-bottom",class:[
              _vm.queryInfo.columnName == 'create_time' && _vm.queryInfo.order == 1
                ? 'isActiveTime1'
                : 'iconColor' ],on:{"click":_vm.caretBottom}})])],1),_c('el-input',{staticClass:"lager-input",attrs:{"placeholder":"根据招聘职位进行搜索"},on:{"change":_vm.search},model:{value:(_vm.queryInfo.queryConditions),callback:function ($$v) {_vm.$set(_vm.queryInfo, "queryConditions", $$v)},expression:"queryInfo.queryConditions"}},[_c('template',{slot:"append"},[_c('div',{on:{"click":_vm.search}},[_vm._v("搜索")])])],2)],1),_c('el-table',{staticClass:"ly_table",attrs:{"data":_vm.projectTable,"header-cell-style":{ background: '#F5F6F7', color: '#363E4D' },"default-sort":{prop: 'date', order: 'descending'},"cell-style":_vm.set_cell_style},on:{"selection-change":_vm.handleSelectionChange,"sort-change":_vm.sortChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"55","align":"center"}}),_c('template',{slot:"empty"},[_c('img',{attrs:{"src":require("@/assets/img/table.png"),"alt":"没有数据","srcset":""}})]),_c('el-table-column',{attrs:{"label":"序号","align":"center","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s((_vm.queryInfo.pageNum-1)*_vm.queryInfo.pageSize+(scope.$index + 1))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"dataIntegerity","label":"数据完整度","sortable":"custom","align":"center","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editItem(row)}}},[_c('el-progress',{attrs:{"type":"circle","percentage":row.dataIntegerity,"stroke-width":12}})],1)]}}])}),_c('el-table-column',{attrs:{"prop":"governmentName","label":"机构名称","align":"center","width":"230"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.editItem(row)}}},[_c('span',[_vm._v(_vm._s(row.governmentName))])])]}}])}),_c('el-table-column',{attrs:{"prop":"governmentPhone","label":"联系电话","align":"center"}}),_c('el-table-column',{attrs:{"prop":"administrativeLevel","label":"行政级别","align":"center"}}),_c('el-table-column',{attrs:{"prop":"higherInstitutions","label":"上级机构","align":"center"}}),_c('el-table-column',{attrs:{"prop":"principal","label":"负责人","align":"center"}},[[_c('div',[_vm._v(" *** ")])]],2),_c('el-table-column',{attrs:{"prop":"responsibilities","label":"职责范围","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',[_c('el-popover',{attrs:{"placement":"right","width":"300","trigger":"hover","content":scope.row.responsibilities}},[_c('div',{attrs:{"slot":"reference"},slot:"reference"},[((scope.row.responsibilities !== null) && (scope.row.responsibilities !== ''))?_c('div',[_vm._v(" "+_vm._s(scope.row.responsibilities.substring(0,20))+" "),(scope.row.responsibilities.length>20)?_c('span',[_vm._v("...")]):_vm._e()]):_vm._e()])])],1)]}}])}),_c('el-table-column',{attrs:{"prop":"createName","label":"创建人","align":"center","width":"80"}}),_c('el-table-column',{attrs:{"prop":"createTime","label":"创建时间","align":"center","width":"120","formatter":_vm.formatTime}}),_c('el-table-column',{attrs:{"label":"操作","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var row = ref.row;
return [_c('button-table',{attrs:{"content":"编辑","icon":"jr-iconjr-icon-edit"},on:{"buttonClick":function($event){return _vm.editItem(row)}}}),_c('button-table',{attrs:{"content":"删除","icon":"jr-iconjr-icon-delect"},on:{"buttonClick":function($event){return _vm.deleteItem(row)}}})]}}])})],2),_c('el-pagination',{attrs:{"current-page":_vm.queryInfo.pageNum,"page-sizes":[10, 30, 50,100,200],"page-size":_vm.queryInfo.pageSize,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }